<template>
  <div>
    <!-- 頭部組件 -->
    <nav-header :type="7"></nav-header>

    <div class="app-container halfTime">
      <div class="box">
        <!-- 頭部准考證號 -->
        <exam-information text="剩餘休息時間"></exam-information>
        <!-- 考題內容 -->
        <div class="box-div">
          <div class="box-div-left">
            <div class="box-div-left-introduce">
              <div class="introduce-title">
                <img src="@/assets/image/examination/halfTime2.png" alt="">
                休息時間
              </div>
              <div class="introduce-content">
                休息時間為10分鐘，請於10分鐘內點擊「進入{{ title }}」按鈕開始作答。<br />
                10分鐘後將自動開始進行「{{ title }}」。
              </div>
            </div>
          </div>
          <div class="box-div-right">
            <img src="@/assets/image/examination/halfTime1.png" alt="">
          </div>
        </div>
        <!-- 測驗開始 -->
        <div class="box-button">
          <div class="button" @click="quizStarts">
            進入{{ title }}
            <img src="@/assets/image/icon/icon-next.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <!-- 底部組件 -->
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import store from '@/store'
import ExamInformation from "./components/ExamInformation.vue"
import ShareButton from "./components/ShareButton.vue"
export default {
  name: 'HalfTime',
  components: {
    ExamInformation,
    ShareButton
  },
  data() {
    return {
      title: '寫作測驗',
      routeUrl: '/writingExam',
      // 倒计时  600秒 十分钟
      countdown: 600,
      timerOut: null
    }
  },
  computed: {
    ...mapGetters(['examType', 'examStatus', 'examAuestionSort', 'examRemainingTime', 'examCountdown', 'problemPopupStatus']),
  },
  watch: {
    // 監聽問題回報彈窗 同步關閉考試倒計時
    problemPopupStatus(val) {
      if (val) {
        // 清除定时器
        clearInterval(this.timerOut);
        this.timerOut = null;
      } else {
        this.countdownHandle()
      }
    }
  },
  mounted() {
    if (this.$route.query.type == 2) { // type == 2 阅读测验过来的  下一步跳转到写作测验
      this.routeUrl = '/writingExam'
      this.title = '寫作測驗'
    } else if (this.$route.query.type == 3) { // type == 3 写作测验过来的  下一步跳转到口说测验
      this.routeUrl = '/reciteExam'
      this.title = '口說測驗'
    }
    this.countdownHandle()
  },
  methods: {
    // 倒计时
    countdownHandle() {
      if (this.countdown > 0) {
        // 时间递减
        this.countdown--
        // 时间赋值
        store.commit('exam/SET_EXAMCOUNTDOWN', this.countdown)
        store.commit('exam/SET_EXAMREMAININGTIME', this.countdown)
        // 重复调用倒计时
        this.timerOut = window.setTimeout(this.countdownHandle, 1000)
      } else {
        // 倒计时结束  跳转页面
        this.quizStarts()
      }
    },


    // 分享熒幕
    shareChange(type) {
      this.$store.commit('shareChange', type)
    },
    // 測驗開始
    quizStarts() {
      this.$router.replace({
        path: this.routeUrl
      })
    }
  },
  beforeDestroy() {
    // 清除定时器
    clearInterval(this.timerOut);        
    this.timerOut = null;
    // 時間置空
    store.commit('exam/SET_EXAMCOUNTDOWN', false)
    store.commit('exam/SET_EXAMREMAININGTIME', 0)
  },
}
</script>

<style lang="less" scoped>
.halfTime {
  .box {
    margin: 12px auto 40px;
    width: 1320px;
    .box-div {
      margin: 12px 0 46px;
      padding: 30px 40px;
      min-height: 566px;
      height: calc(100% - 400px);
      background: #FFFFFF;
      border-radius: 8px;
      position: relative;
      display: flex;
      align-items: center;
      .box-div-left {
        width: 560px;
        .box-div-left-introduce {
          padding: 24px 20px 38px;
          background: #F7F8FB;
          border-radius: 8px;
          .introduce-title {
            display: flex;
            align-items: center;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 43px;
            color: #333333;
            img {
              margin-right: 12px;
              width: 35px;
              height: 43px;
            }
          }
          .introduce-content {
            margin-top: 20px;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            color: #333333;
          }
        }
      }
      .box-div-right {
        width: calc(100% - 560px);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-top: 60px;
          width: 250px;
          height: 300px;
        }
      }
    }
    .box-button {
      display: flex;
      justify-content: flex-end;
      .button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 280px;
        height: 60px;
        background: #50CE8E;
        border-radius: 8px;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        img {
          margin-left: 6px;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
</style>